import request from '@/utils/request'

export function getGroupInfo(group_id) {
  return request({
    url: '/group/info',
    method: 'get',
    params: { group: group_id }
  })
}
export function createGroup(data) {
  return request({
    url: '/group/create',
    method: 'post',
    data
  })
}

export function getGroupList(project_id) {
  return request({
    url: '/group/list',
    method: 'get',
    params: { project: project_id }
  })
}

export function getTinyGroupList(project) {
  return request({
    url: '/group/tinylist',
    method: 'get',
    params: { project: project }
  })
}


export function getGroupName(group) {
  return request({
    url: '/group/name',
    method: 'get',
    params: { group: group }
  })
}

export function deleteGroup(group) {
  return request({
    url: '/group/delete',
    method: 'delete',
    data: { group: group }
  })
}

export function getGroupUpdateInfo(group) {
  return request({
    url: '/group/updateinfo',
    method: 'get',
    params: { group: group }
  })
}

export function updateGroup(group, data) {
  return request({
    url: '/group/update/' + group,
    method: 'put',
    data
  })
}