/* eslint-disable */
import { getJssdkParam, getPayParam, uploadWechatImage } from "@/api/other";
import { Toast, Notify } from "vant";

const wxSdk = {
    pay(order) {
        getPayParam(order).then(response => {
            wx.config(response.sdk);
            let pay = response.pay
            wx.ready(function () {
                wx.chooseWXPay({
                    timestamp: pay.timestamp,
                    nonceStr: pay.nonceStr,
                    package: pay.package,
                    signType: pay.signType,
                    paySign: pay.paySign,
                    success: function (res) {
                        console.log('成功')
                    }
                });

            })
            wx.error(function (res) {
                //Notify({ type: 'primary', message: '部分功能加载失败,建议刷新页面', duration: 1000 });
            });
        })
    },
    address() {
        return new Promise(function (resolve, reject) {
            getJssdkParam(window.location.href).then(response => {
                wx.config(response);
                wx.ready(function () {
                    wx.openAddress({
                        success: function (res) {
                            resolve(res)
                        }
                    });
                })
                wx.error(function (res) {
                    //Notify({ type: 'primary', message: '部分功能加载失败,建议刷新页面', duration: 1000 });
                });
            })
        })
    },
    share(title, desc, link, imgurl = 'https://m.isdut.cn/static/sdutlogo180.jpg') {
        getJssdkParam(window.location.href).then(response => {
            wx.config(response);
            wx.ready(function () {
                wx.updateAppMessageShareData({
                    title: title,
                    desc: desc + '\n山东理工大学教育发展基金会',
                    link: link,
                    imgUrl: imgurl,
                    success: function () {
                    }
                })
                wx.updateTimelineShareData({
                    title: title,
                    link: link,
                    imgUrl: imgurl,
                    success: function () {
                    }
                })
            })
            wx.error(function (res) {
                //Notify({ type: 'primary', message: '部分功能加载失败,建议刷新页面', duration: 1000 });
            });
        })
    },
    uploadImage() {
        return new Promise(function (resolve, reject) {
            getJssdkParam(window.location.href).then(response => {
                wx.config(response);
                wx.ready(function () {
                    wx.chooseImage({
                        count: 1,
                        sizeType: ["original", "compressed"],
                        sourceType: ["album", "camera"],
                        success: function (res) {
                            let uploading = Toast.loading("正在上传...")
                            wx.uploadImage({
                                localId: res.localIds[0],
                                isShowProgressTips: 0,
                                success: function (res) {
                                    uploadWechatImage({ media: res.serverId }).then(response => {
                                        uploading.clear()
                                        resolve(response.path)
                                    })
                                },
                                error: function () {
                                    uploading.clear()
                                }
                            });
                        },
                    });
                })
                wx.error(function (res) {
                    //Notify({ type: 'primary', message: '部分功能加载失败,建议刷新页面', duration: 1000 });
                });
            })
        })
    },
    navigateWxMP() {
        return new Promise(function (resolve, reject) {
            getJssdkParam(window.location.href).then(response => {
                wx.config(response);
                wx.ready(function () {
                    resolve()
                })
            })
        })
    }
}

export default wxSdk