<template>
    <div class="sdut-banner">
        <img v-lazy="imgUrl" alt="media" />
    </div>
</template>
<script>
import SdutLogo from '@/assets/images/sdutjjh.png'
export default {
  data () {
    return {
      imgUrl: SdutLogo
    }
  }
}
</script>

<style>
.sdut-banner{
    width: 100%;
    text-align: center;
    height: 7rem;
}

.sdut-banner img{
    width: 70%;
}
</style>
