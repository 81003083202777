import request from '@/utils/request'

export function createShareImage(data) {
  return request({
    url: '/share/create',
    method: 'post',
    data
  })
}

export function createGroupShareImage(data) {
  return request({
    url: '/share/group',
    method: 'post',
    data
  })
}

export function getJssdkParam(url = null) {
  return request({
    url: '/jssdk',
    method: 'get',
    params: { url: url }
  })
}

export function getHomeData() {
  return request({
    url: '/home',
    method: 'get'
  })
}

export function getPayParam(order) {
  return request({
    url: `/pay/${order}`,
    method: 'get'
  })
}

export function getPageData(page) {
  return request({
    url: '/page/data',
    method: 'get',
    params: { page: page }
  })
}

export function uploadWechatImage(data) {
  return request({
    url: '/upload/image',
    method: 'post',
    data
  })
}

