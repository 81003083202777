<template>
  <div class="page-container">
    <div class="block-box">
      <div class="block-title">
        <div class="block-title-left">编辑团队</div>
      </div>
    </div>
    <div class="create-group-form">
      <van-form ref="updategroupform" @submit="handleUpdateGroup">
        <van-field
          v-model="group.name"
          name="团队名称"
          label="团队名称"
          placeholder="团队名称无法编辑"
          readonly
          right-icon="warning-o"
          @click="handleClickName"
        />
        <van-field
          v-model="group.leader"
          name="团队队长"
          label="团队队长"
          placeholder="请填写团队队长"
          maxlength="6"
          show-word-limit
          :rules="[{ required: true, message: '请填写该字段' }]"
        />
        <van-field
          v-model="group.aim"
          name="目标金额"
          label="目标金额"
          placeholder="设置目标金额"
          type="number"
          :rules="[{ required: true, message: '请填写该字段' }]"
        />
        <van-field
          v-model="group.message"
          name="团队口号"
          label="团队口号"
          placeholder="写下团队口号"
        />
      </van-form>
      <van-cell title="团队标志">
        <template #default>
          <div style="text-align: left">
            <van-button size="mini" type="danger" plain @click="uploadLogo"
              >上传图片</van-button
            >
            <van-button
              v-if="selectLogo"
              size="mini"
              plain
              @click="selectLogo = null"
              >删除图片</van-button
            >
          </div>
        </template>
      </van-cell>
      <van-cell title="标志预览" v-if="selectLogo">
        <template #default>
          <div style="text-align: left">
            <van-image width="140" height="140" :src="selectLogo" />
          </div>
        </template>
      </van-cell>
      <p style="text-align: left; font-size: 0.7rem; margin-left: 1rem" v-else>
        标志:建议上传正方形图片,不上传默认使用您的头像作为团队标志
      </p>
      <div style="margin: 16px">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          color="red"
          @click="$refs.updategroupform.submit()"
        >
          保存
        </van-button>
      </div>
    </div>
    <sdut-banner />
  </div>
</template>

<script>
import SdutBanner from "@/components/SdutBanner";
import { getGroupUpdateInfo, updateGroup } from "@/api/group";
import wxSdk from "@/utils/wxsdk";
import { Notify } from "vant";
export default {
  components: { SdutBanner },
  data() {
    return {
      gid: null,
      group: {
        name: null,
        aim: null,
        message: null,
        leader: null,
        avatar: null,
      },
      selectLogo: null,
    };
  },
  mounted() {
    this.gid = this.$route.params.group;
    this.fetchData();
  },
  methods: {
    fetchData() {
      getGroupUpdateInfo(this.gid).then((response) => {
        this.group = response;
        if (response.avatar) {
          this.selectLogo = response.avatar;
        }
      });
    },
    handleUpdateGroup() {
      this.group.avatar = this.selectLogo;
      updateGroup(this.gid, this.group).then((response) => {
        this.$router.push({ path: `/group/${response.group}` });
      });
    },
    uploadLogo() {
      wxSdk.uploadImage().then((path) => {
        this.selectLogo = path;
      });
    },
    handleClickName() {
      Notify({ type: "primary", message: "团队名称无法更改" });
    },
  },
};
</script>

<style>
.create-group-form {
  background-color: #ffffff;
}
</style>
